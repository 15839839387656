import './App.css';
import React from 'react';
import ShowNFTs from './ShowNFTs';


const App = () => {
	return (
		<ShowNFTs/>
	);
}
export default App;