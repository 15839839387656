import React, { useEffect, useState } from 'react';
import uris from './Data/uris.json'
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardContent, CardMedia, CardActions, CardActionArea} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {connectWallet,getCurrentWalletConnected} from "./ConnectWallet";
import fetch from 'node-fetch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, List, ListItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import img from './Data/stakingLogo.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const meta = [];
let allData;
let tokenIDs = [];

const stakedNFTsList = [];
let allStakedData;
let stakedTokenIDs = [];

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const nftContractAddress = "0x613485c528BdEbfef7Bed764AA4D6e4BA30Ef565";
const stakingContractAddress = "0xd9a923B6CE8540048C8832CA9642Cab95360f942"
const tokenContractAddress = "0x8F4A643850ab7993A716e2E1d88FF00B93324dC7"
const stakingABI = require("./Data/stakingABI.json");
const tokenABI = require("./Data/TokenABI.json");
const NFTABI = require("./Data/NFTABI.json");
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);


const useStyles = makeStyles({
    DashboardCards:{
        width: '200px',
        height: '200px',
        paddingLeft: "1rem",
        paddingRight: "1rem",
        marginRight: "1rem",
    },
    PaperBox: { 
        height: 750,
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        paddingTop: "1rem",
        align: 'center',
        justify: 'center',
        overflowY: 'scroll', 

    },
    StakeBox: {
        width: 2000,
        maxWidth: '100%',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent:'center',
        marginTop: '1rem'
    }, 
    GridBox: { 
        marginBottom: '20px',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent:'center',
    },
    TypeStyles: {
        fontSize: "34px",
        color: "#FFFFFF",
    },
    List: {
        display: 'flex', 
        flexDirection: 'row', 
        justify: 'space-between', 
        alignItems: 'center', 
        alignContent: 'center', 
        paddingTop: '1rem',
        paddingBottom: '1rem'
    },
    appBar: {
        flexGrow: 1,
        backgroundColor:  'rgba(0%, 0%, 0%, 0)',
        display: 'flex',
    },
    logo: {
        display: 'flex',
        position: 'relative',
        flexGrow: 1,
        paddingTop: 5, 
        paddingBottom: 5,
        margin: '0 auto',
    },
    ButtonStyle: {
        backgroundColor: '#fff28c',
        textColor: 'white',
    }, 
    CardContent: {
        justifyContent: 'center',
        align: 'center',
        justify: 'center',
        display: 'flex',
    },
    DashBoardType: {
        fontSize: "34px",
        display: 'flex', 
        justifyContent: 'center'
    }, 
    DashboardTitle: {fontSize: '20px',paddingTop: '1.5rem', display: 'flex', justifyContent: 'center'}
    });


const ShowNFTs = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [unstake, setUnstake] = useState("");
    const [nftStaked, setNFTStakes] = useState([]);
    const [earned, setEarnings] = useState(0);
    const [open, setOpen] = useState(false);
    const callNFTContract = new web3.eth.Contract(NFTABI, nftContractAddress);
    const callStakingContract = new web3.eth.Contract(stakingABI, stakingContractAddress);
    const callTokenContract = new web3.eth.Contract(tokenABI, tokenContractAddress);
    
    
    const getWalletListener = async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);
        setStatus(status);
        addWalletListener();
    }
    function addWalletListener() {
        if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {
            if (accounts.length > 0) {
            setWallet(accounts[0]);
            setStatus("👆🏽 Write a message in the text-field above.");
            } else {
            setWallet("");
            setStatus("🦊 Connect to Metamask using the top right button.");
            }
        });
        } else {
        setStatus(
            <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
            </a>
            </p>
        );
        }
    }

    const connectWalletPressed = async () => {
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
        return walletAddress;
    };
    
    const validateWallet = async () => {
        if (walletAddress === "") {
            const gettingAddress = await getWalletListener();
            if (gettingAddress !== "") { return gettingAddress; } 
            else {
                    const gettingAddress = await connectWalletPressed();
                    return gettingAddress;
            }
        }
    }

    useEffect( () => {
    const fetchData = async () => {
        await validateWallet();
        if (walletAddress !== "") {
            try {
                await checkBalance();
                const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
                const endpoint = `https://eth-mainnet.alchemyapi.io/v2/${apiKey}`;
                const getTokenIds = await fetch(`${endpoint}/getNFTs?owner=${walletAddress}&contractAddresses%5B%5D=0x613485c528BdEbfef7Bed764AA4D6e4BA30Ef565&withMetadata=false`).then(getTokenIds => getTokenIds.json())
                setData(getTokenIds.ownedNfts)
            } catch (error) {
                console.log(error);
        }
        }
    }
    fetchData()
    },[walletAddress])


    const prepData = async () => {
        meta.length = 0
        for (let i = 0; i < data.length; i++) {
            let parsedTokenID = web3.utils.toNumber(data[i].id.tokenId)
            tokenIDs.push(parsedTokenID);
            const urls = uris.filter((token)=>token.tokenID === parseInt(parsedTokenID))
                allData = {
                    token: parsedTokenID,
                    imageLink: urls[0].uri
                }
                meta.push(allData);
            }
    }
    prepData();
    
    const handleStakeChange = (e) => {
        const index = tokens.indexOf(e.target.value);
        if (index === -1) {
            setTokens([...tokens, e.target.value])
        } else {
            setTokens(tokens.filter((i => i !== e.target.value)))
        }
    }

    const handleUnStakeChange = (e) => {
        const index = unstake.indexOf(e.target.value);
        if (index === -1) {
            setUnstake([...unstake, e.target.value])
        } else {
            setUnstake(unstake.filter((i => i !== e.target.value)))
        }
    }

    const [isApproved, setApproved] = useState()
    const getApproval = async () => {
        if (walletAddress !== "") { 
            try {
                const checkApproval = await callNFTContract.methods.isApprovedForAll(walletAddress, stakingContractAddress).call({from: walletAddress}).then(result => result)
                setApproved(checkApproval)
            } catch (error) {
                console.log(error);
            }
        }
    };
    
    getApproval();

    const setApprovalForAll = async () => {
        await getApproval();
        if (walletAddress !== "" && isApproved === false) {
            try {
                const callNFTContract = new web3.eth.Contract(NFTABI, nftContractAddress);
                await callNFTContract.methods
                .setApprovalForAll(stakingContractAddress, "true").send({from:walletAddress})                
            } catch (error) {
                console.log(error);
            }
        }
    }
    
    const stake = async ()=> {
        await getApproval();
        if (tokens.length > 0 && isApproved === false) {
            handleClickOpen();
            await getApproval();
            if (tokens.length > 0 && isApproved === true) { 
                callStakingContract.methods.stake(tokens).send({from: walletAddress});
            }
        } 
        else if (tokens.length > 0 && isApproved === true) { 
            callStakingContract.methods.stake(tokens).send({from: walletAddress});
        }
    }

    const getNFTsStaked = async() => {
        if (walletAddress !== "") {
        return await callStakingContract.methods.tokensOfOwner(walletAddress).call((err, result) => {
            try {
                setNFTStakes(result)
            } catch (err) {
                console.log(err);
            } 
        })}
    }

    useEffect( () => {
        getNFTsStaked();
        getCommunityBalance();

    },[walletAddress])

    const getEarningInfo = async () => {
        if (walletAddress !== "" && nftStaked.length !== 0) {
            try {
                const rawearn = await callStakingContract.methods.earningInfo(walletAddress, nftStaked).call();
                const earned =  String(rawearn).split(",")[0];
                const earnedrwd = web3.utils.fromWei(earned);
                const rewards = Number(earnedrwd).toFixed(2);
                setEarnings(rewards)
            } catch (error) {
                console.log(error);
                window.location.reload();
            }
    }}
    useEffect( () => {
        getEarningInfo();
    },[nftStaked])
    useEffect( () => {
        if (walletAddress !== "" && nftStaked.length !== 0) {
            try {
                const interval = setInterval(async() => {
                    await getNFTsStaked();
                    await getEarningInfo();
                    await getCommunityBalance();
                    }, 30000);
                    return () => clearInterval(interval);   
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const interval = setInterval(async() => {
                    await getCommunityBalance();
                    }, 30000);
                    return () => clearInterval(interval);   
            } catch (error) {
                console.log(error);
            }
        }
        });
    
    const claimTokens = async () => { 
        if (earned > 0) {
            callStakingContract.methods.claim(nftStaked).send({from: walletAddress});
        }
    }

    const unstakeSelect = async () => {
        if (unstake.length > 0 ) { 
            callStakingContract.methods.unstake(unstake).send({from: walletAddress});
        }
    }

    const prepStakedTokens = async () => {
        stakedNFTsList.length = 0
        for (let i = 0; i < nftStaked.length; i++) {
            stakedTokenIDs.push(nftStaked[i]);
            const urls = uris.filter((token)=>token.tokenID === parseInt(nftStaked[i]))
                allStakedData = {
                    token: nftStaked[i],
                    imageLink: urls[0].uri
                }
                stakedNFTsList.push(allStakedData);
            }
    }
    const [tokenBalance, setTokenBalance] = useState(0.0)
    prepStakedTokens();

    const checkBalance = async () => {
        const getTokenBalance = await callTokenContract.methods.balanceOf(walletAddress).call({from: walletAddress});
        const rawBalance =  String(getTokenBalance).split(",")[0];
        const processBalanceToWei = web3.utils.fromWei(rawBalance);
        const cleanBalance = Number(processBalanceToWei).toFixed(2);
        setTokenBalance(cleanBalance);
    }

    const [communityBalance, setCommunityBalance] = useState(0)

    const getCommunityBalance = async () => {
        const callTotalStaked = await callStakingContract.methods.totalStaked().call({from: walletAddress});
        setCommunityBalance(callTotalStaked)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setApprovalForAll();
    };
    const handleExit = (value) => {
        setOpen(false);
    };

    /////////////////////
    // ENF OF COMPONENT//
    /////////////////////

    return (
        
        <div>
            <Box >
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar>
                    <Typography className={classes.logo}>
                        <img src={img} width="auto" height={100}/>
                    </Typography>
                        <Button variant="contained" className={classes.ButtonStyle} onClick={connectWalletPressed}>
                                {walletAddress.length > 0 ? (
                                    "Connected: " +
                                    String(walletAddress).substring(0, 6) +
                                    "..." +
                                    String(walletAddress).substring(38)
                                ) : (
                                    <span>Connect Wallet</span>
                                )}
                        </Button>                        
                    </Toolbar>
                </AppBar>
            </Box>
                
                <Box>
                    <Container style={{ direction: 'row', display: 'flex', height: '100%', width: '1000px', padding: '20px', justify: 'center' }} >
                        <Card className={classes.DashboardCards}>
                                <Typography gutterBottom variant="h1" className={classes.DashboardTitle}>
                                Currently Earning
                                </Typography>
                            <CardContent classes={{root: classes.CardContent}}>
                                <Typography variant="h2" className={classes.DashBoardType}>
                                {earned}
                                </Typography>
                            </CardContent>
                            <CardActions classes={{root: classes.CardContent}}>
                                <Button variant="contained"  className={classes.ButtonStyle} onClick={claimTokens}>
                                    Claim Tokens
                                </Button>
                            </CardActions>
                        </Card>
                        <Card className={classes.DashboardCards}>
                                <Typography gutterBottom variant="h1" className={classes.DashboardTitle}>
                                $EUPHORIA Balance
                                </Typography>
                            <CardContent classes={{root: classes.CardContent}}>
                            <Typography variant="h2" className={classes.DashBoardType}>
                                {tokenBalance}
                                </Typography>
                            </CardContent>
                            <CardActions classes={{root: classes.CardContent}}>
                                <Button variant="contained" className={classes.ButtonStyle} onClick={checkBalance}>
                                    Refresh Balance
                                </Button>
                            </CardActions>
                        </Card>
                        <Card className={classes.DashboardCards}>
                                <Typography gutterBottom variant="h1" className={classes.DashboardTitle}>
                                NFTs Currently Staked
                                </Typography>
                            <CardContent classes={{root: classes.CardContent}}>
                            <Typography variant="h2" className={classes.DashBoardType}>
                                {nftStaked.length}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.DashboardCards}>
                                <Typography gutterBottom variant="h1" className={classes.DashboardTitle}>
                                Total NFTs by Community 
                                </Typography>
                            <CardContent classes={{root: classes.CardContent}}>
                            <Typography variant="h2" className={classes.DashBoardType}>
                                {communityBalance}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Container>
                </Box>

                <Container className={classes.StakeBox}>
                        <Grid container spacing={1} direction="row">
                            <Grid item xs={6}>
                                {/* To Stake Cards */}
                                <Container style={{backgroundColor: 'rgba(255, 255, 255, 0.25)'}}>
                                    <List className={classes.List}>
                                        <ListItem>
                                            <Typography variant="h2" className={classes.TypeStyles}>
                                                Your wallet
                                            </Typography>
                                        </ListItem>
                                        <ListItem style={{ justifyContent: 'flex-end' }}>
                                            <Button variant="contained" className={classes.ButtonStyle} onClick={stake}>
                                                Stake NFTs
                                            </Button>
                                        </ListItem>
                                    </List>
                                </Container>
                                <Box className={classes.PaperBox}>
                                    <Container>
                                        <Grid container direction="row">
                                            {meta.map((values, index) => {
                                                return (
                                                    <Grid item xs={3} key={index} className={classes.GridBox}>
                                                        <Card>
                                                            <CardActionArea>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={values.imageLink}
                                                                    style={{ objectFit: "cover", width: 180 }} />
                                                                <CardContent>
                                                                    <Typography gutterBottom variant="h6">
                                                                        ID: {values.token}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                            <CardActions>
                                                                <FormControlLabel
                                                                    value={values.token}
                                                                    label="Stake"
                                                                    control={<Checkbox onChange={handleStakeChange} />} />
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Container>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                {/* Unstake Card*/}
                                <Container style={{backgroundColor: 'rgba(255, 255, 255, 0.25)'}} >
                                        <List className={classes.List}>
                                            <ListItem>
                                                <Typography variant="h2" className={classes.TypeStyles}>
                                                    Staking Vault
                                                </Typography>
                                            </ListItem>
                                            <ListItem style={{ justifyContent: 'flex-end' }}>
                                                <Button variant="contained" className={classes.ButtonStyle} onClick={unstakeSelect}>
                                                    Unstake NFTs
                                                </Button>
                                            </ListItem>
                                        </List>
                                    </Container>
                                <Box className={classes.PaperBox}>
                                    <Container alignItems="center">
                                        <Grid container spacing={1} direction="row">
                                            {stakedNFTsList.map((stakedValues, index) => {
                                                return (
                                                    <Grid item xs={3} key={index} className={classes.GridBox}>
                                                        <Card className={classes.root}>
                                                            <CardActionArea>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={stakedValues.imageLink}
                                                                    style={{ objectFit: "cover", width: 180 }} />
                                                                <CardContent>
                                                                    <Typography gutterBottom variant="h6" component="h3" alignItems="center">
                                                                        NFT ID: {stakedValues.token}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                            <CardActions>
                                                                <FormControlLabel
                                                                    value={stakedValues.token}
                                                                    label="Unstake"
                                                                    control={<Checkbox onChange={handleUnStakeChange}/>} />
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Container>
                                </Box>
                            </Grid>
                        </Grid>
                </Container>

                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{"Approve Euphorians Staking Vault"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            To be able to safely transfer your Euphorian NFTs from your wallet to the Euphorians Staking Vault and earn $Euphoria tokens, you must first give the Euphorians Staking Vault approval. Note that this is a one time transaction only needed when you stake for the frist time.  
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleExit} color="primary">
                            Close
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Set Approval
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
    );   
};

export default ShowNFTs;
